@import "../../../scss/variables.scss";
@import "../../../scss/colors.scss";

.editor-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  .left {
    display: flex;
    flex: 1;
    flex-direction: row;

    &__left {
      flex: 3;
    }

    &__right {
      width: 60vh;

      .board-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        min-height: 80vh;

        .selection-area {
          min-height: 7vh;
          max-height: 7vh;
          width: 60vh;
          background-color: $secondary-color;
          border-radius: $border-radius;
          display: grid;
          grid-template-columns: repeat(8, minmax(0, 1fr));
        }

        .castling-area {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          width: 100%;
        }

        .disabled {
          opacity: 0.2;
          cursor: not-allowed !important;
          pointer-events: none !important;
        }

        .board-area {
          position: relative;
          height: 60vh;
          width: 60vh;
          border: 1px solid $primary-color;
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: repeat(8, 1fr);

          .cell {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            user-select: none;

            img {
              width: 90%;
              height: 90%;
              -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
              user-select: none;
            }
          }

          .light-cell {
            background-color: #f0d9b5;
          }

          .dark-cell {
            background-color: #b58862;
          }

          .vertical-ruler {
            position: absolute;
            text-align: center;
            right: 0;
            top: 0;
            width: 10px;
            height: 100%;
            font-size: 10px;
            display: grid;
            grid-template-rows: repeat(8, 1fr);
            font-weight: 500;
            user-select: none;

            .rule {
              padding-top: 5px;
              user-select: none;
            }
          }

          .horizontal-ruler {
            position: absolute;
            left: 0;
            bottom: 4px;
            width: 100%;
            height: 12px;
            font-size: 10px;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            font-weight: 500;
            user-select: none;

            .rule {
              padding-left: 3px;
              user-select: none;
            }
          }
        }

        .fen-displayer {
          width: 100%;
          margin-top: 10px;
          text-align: center;
        }
      }

      .input-container {
      }
    }
  }

  .right {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    padding-top: 25px;

    .menu-container {
      width: 20vw;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .movement-container {
        height: 350px;
        border-radius: 6px;
        background-color: #262421;
        overflow: auto;
        padding: 15px 20px;

        .title {
          font-weight: bold;
          font-size: 14px;
          color: #989797;
        }

        .list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .item {
            padding: 5px;

            margin-top: 15px;
            flex-basis: 45%;
          }
        }
      }

      .game-info-card {
        background-color: #262421;
        border-radius: 6px;
        padding: 15px;
        display: flex;
        flex-direction: column;

        .castling {
          padding: 10px 0;
          .title {
            font-weight: bold;
            font-size: 12px;
            color: #989797;
          }

          .castling-option {
            display: flex;
            flex-direction: row;
            text-align: center;
            justify-content: center;

            .label {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: 600;
            }

            .action {
              flex: 8;
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .en-passant {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .label {
            font-size: 12px;
            font-weight: bold;
          }
        }
      }

      .select-game-card {
        background-color: #262421;
        border-radius: 6px;

        .game-type-select {
          .game-type-txt {
            font-size: 8px;
          }
        }
      }

      .action-btn-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.editor-container:focus {
  outline: none;
  border-color: #000000;
}
