@import "../../../scss/variables.scss";
@import "../../../scss/colors.scss";

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $background-gradient;
  height: $header-bar-height;
  display: flex;
  align-content: center;
  padding: 0 20px;
  z-index: 1;
  justify-content: space-between;

  .end-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 60px;
  }
}
