$primary-color: #161512;
$secondary-color: #6b6b6b;

// Header Menu
$background-gradient: linear-gradient(
  180deg,
  rgba(22, 21, 18, 1) 0%,
  rgba(26, 25, 22, 1) 50%,
  rgba(22, 21, 18, 1) 100%
);
