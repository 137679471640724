.tile {
  display: grid;
  place-content: center;
  width: 50px;
  height: 50px;
}

.tile .chess-piece {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}

.tile .chess-piece:hover {
  cursor: grab;
}

.tile .chess-piece:active {
  cursor: grabbing;
}

/* .black-tile {
  background-color: #779556;
}

.white-tile {
  background-color: #ebecd0;
} */

.tile img {
  width: 40px;
}

.tile-highlight:not(.chess-piece-tile)::before {
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
}

.tile-highlight.chess-piece-tile::before {
  position: absolute;
  content: " ";
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}
