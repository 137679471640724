.hw-generator {
  padding: 10px 30px;

  &__input-row {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    &__label {
      width: 150px;
    }

    &__input {
      width: 870px;
      max-width: 870px;
    }

    &__submit {
      width: 1010px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
