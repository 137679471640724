html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #161512;
}

#root {
  height: 100%;
  background-color: #161512;
  color: #929292;
}
