.selection-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;

  .custom-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }

  .select {
    font-size: 5vh;
  }

  .remove {
    font-size: 5.5vh;
  }

  img {
    max-width: 85%;
    max-height: 85%;
    height: auto;
  }
}

.selected {
  background-color: #537eaa;
}

.selected-remove {
  background-color: #a74d4b;
}
